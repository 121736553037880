<template>
  <b-row>
    <b-col cols="12">
      <div class="card card-box">
        <ul class="list-group list-group-flush">
          <li
            v-for="(groupedVideoLesson, index) in groupedVideoLessons"
            :key="`lesson__videos-act-${index}`"
            class="list-group-item pt-4 pb-4"
          >
            <div class="mb-1 font-size-lg font-weight-bold">
              บทเรียนที่ {{ index + 1 }}
            </div>

            <div
              v-for="videoLesson in groupedVideoLesson"
              :key="`lesson__video-${videoLesson.id}`"
              class="d-flex flex-column flex-sm-row align-items-start mt-4"
            >
              <div>
                <div class="card card-transparent mb-3 mb-sm-0">
                  <a
                    target="_blank"
                    :href="videoLesson.lesson_watch_url"
                    class="card-img-wrapper rounded"
                    style="width: 160px"
                  >
                    <div class="img-wrapper-overlay">
                      <div class="overlay-btn-wrapper">
                        <b-button
                          size="sm"
                          pill
                          variant="outline-secondary"
                          class="
                            border-0
                            shadow-none
                            pl-2
                            pr-2
                            mx-auto
                            d-flex
                            align-items-center
                          "
                        >
                          <font-awesome-icon
                            icon="play-circle"
                            class="font-size-lg"
                          />
                        </b-button>
                      </div>
                    </div>

                    <b-embed type="video">
                      <source
                        :src="`${videoLesson.lesson_watch_url}#t=2`"
                        type="video/mp4"
                      />
                    </b-embed>
                  </a>
                </div>
              </div>

              <div class="pl-0 pl-sm-4">
                <p class="mb-0 mt-2">
                  {{ videoLesson.lesson_title }}
                </p>
              </div>

              <div class="mt-3 mt-sm-0 ml-sm-auto">
                <b-button
                  variant="primary"
                  target="_blank"
                  :href="videoLesson.lesson_watch_url"
                  class="mr-2"
                >
                  <span class="btn-wrapper--icon">
                    <font-awesome-icon icon="play-circle" class="mr-2" />

                    <span>ดูบทเรียน</span>
                  </span>
                </b-button>

                <b-button
                  variant="danger"
                  @click.prevent="download(videoLesson)"
                >
                  <b-spinner
                    v-if="isDownloading && isDownloadingId === videoLesson.id"
                    label="ดาวน์โหลด..."
                  ></b-spinner>

                  <span v-else class="btn-wrapper--icon">
                    <font-awesome-icon icon="file-download" class="mr-2" />

                    <span>ดาวน์โหลด</span>
                  </span>
                </b-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { chain } from "lodash";
import { Lesson } from "../../models";

export default {
  data() {
    return {
      isDownloading: false,
      isDownloadingId: null,
    };
  },

  computed: {
    videoLessons() {
      return Lesson.query().where("lesson_type", "video").get();
    },

    groupedVideoLessons() {
      return chain(this.videoLessons)
        .groupBy("lesson_no")
        .sortBy("lesson_order")
        .value();
    },
  },

  methods: {
    async download(lesson) {
      this.isDownloading = true;
      this.isDownloadingId = lesson.id;

      try {
        const url = await lesson.getDownloadUrl();

        const a = document.createElement("a");

        a.href = url;

        a.click();

        a.remove();
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดาวน์โหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isDownloading = false;
        this.isDownloadingId = null;
      }
    },
  },
};
</script>
